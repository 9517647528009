import Container from "./container";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/initiative-header.module.css";

const InitiativeHeader = (props) => {
  const {
    title,
    websiteUrl
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className="containerContentLg">
          <div className={cn(styles.category, "h3")}>Initiative</div>
          {title && <h1 className={cn(styles.heading, "h2")}>{title || "Untitled"}</h1>}
          {websiteUrl && <a className={cn(styles.link, "linkExternal")} href={websiteUrl} target="_blank" rel="noreferrer">Visit Website<Icon symbol="external" /></a>}
        </div>
      </Container>
    </div>
  );
}

export default InitiativeHeader;
