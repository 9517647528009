import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Container from "../components/container";
import Seo from "../components/seo";

import InitiativeHeader from "../components/initiative-header";
import InitiativeFeaturedMedia from "../components/initiative-featured-media";
import FlexibleContent from "../components/flexible-content";
import InitiativeGallery from "../components/initiative-gallery";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query InitiativePageTemplateQuery($id: String!) {
    initiative: sanityInitiative(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      websiteUrl
      featuredImage {
        ...SanityImage
        alt
      }
      featuredVideo {
        embedId
        cover {
          ...SanityImage
          alt
        }
      }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
          rightAligned
        }
        ... on SanityFcContentBlockWithMedia {
          _key
          _type
          image {
            ...SanityImage
            alt
          }
          video {
            embedId
            cover {
              ...SanityImage
              alt
            }
          }
          _rawContent
          mediaRightPositioned
        }
        ... on SanityFcImage {
          _key
          _type
          image {
            ...SanityImage
            alt
          }
          rightAligned
        }
        ... on SanityPressReleaseReference {
          _key
          _type
          pressRelease {
            _key
            publishedOn
            link
            file {
              asset {
                url
                originalFilename
              }
            }
            title
          }
        }
      }
      gallery {
        _key
        ...SanityImage
        alt
        caption
      }
      excerpt
    }
  }
`;

const InitiativePageTemplate = (props) => {
  const { 
    data, 
    errors
  } = props;
  
  const initiative = data && data.initiative;

  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {initiative && (
        <Seo
          title={initiative.title}
          description={initiative.excerpt}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {(initiative.title || initiative.websiteUrl) && <InitiativeHeader title={initiative.title} websiteUrl={initiative.websiteUrl} />}

      {(initiative.featuredImage || initiative.featuredVideo) && <InitiativeFeaturedMedia featuredImage={initiative.featuredImage} featuredVideo={initiative.featuredVideo} title={initiative.title} />}

      {initiative.flexibleContent && <FlexibleContent items={initiative.flexibleContent} />}

      {initiative.gallery.length ? <InitiativeGallery gallery={initiative.gallery} /> : null}
    </Layout>
  );
};

export default InitiativePageTemplate;
