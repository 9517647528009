import React, { useRef } from "react";
import Container from "./container";
import Icon from "./icon";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/initiative-featured-media.module.css";

const InitiativeFeaturedMedia = (props) => {

  const {
    title,
    featuredImage,
    featuredVideo
  } = props;

  const mediaRef = useRef(null);

  function handlePlayVideo() {
    mediaRef.current.parentElement.classList.add(styles.active);
    mediaRef.current.src = mediaRef.current.src + '&autoplay=1';
  }

  return (
    <div className={styles.root} data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease-out">
      <Container>
        {featuredImage && featuredImage.asset && (!featuredVideo || !featuredVideo.embedId) && (
          <div className={styles.image}>
            <img
              src={imageUrlFor(buildImageObj(featuredImage))
                .auto("format")
                .url()}
              alt={featuredImage.alt}
            />
          </div>
        )}

        {featuredVideo && featuredVideo.embedId && (
          <div className={styles.video}>
            <div className={styles.videoWrap}>
              {featuredVideo.cover && featuredVideo.cover.asset && (
                <div className={styles.cover}>
                  <img
                    src={imageUrlFor(buildImageObj(featuredVideo.cover))
                      .auto("format")
                      .url()}
                    alt={featuredVideo.cover.alt}
                  />
                </div>
              )}
              <div className={styles.action} onClick={handlePlayVideo} onKeyDown={handlePlayVideo} role="button" tabIndex={0}>
                <Icon symbol="play" />
              </div>
              <div className={styles.player}>
                <iframe title={title} src={"https://player.vimeo.com/video/"+featuredVideo.embedId+"?transparent=0"} width="640" height="360" frameborder="0" ref={mediaRef} allow="autoplay" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default InitiativeFeaturedMedia;
