import React from "react";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Icon from "./icon";
import Slider from "react-slick";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/initiative-gallery.module.css";

const Gallery = (props) => {

  const {
    gallery
  } = props;

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button type="button" className={cn(styles.galleryArrow, styles.prev)} onClick={onClick}><span>Previous</span><Icon symbol="arrow-left-lg" /></button>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <button type="button" className={cn(styles.galleryArrow, styles.next)} onClick={onClick}><span>Next</span><Icon symbol="arrow-right-lg" /></button>
    );
  }

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  return (
    <div className={styles.root}>
      <Container>
        <Slider {...settings}>
          {gallery.map((node) => {
            return (
              <div key={node._key} className={styles.item}>
                {node && node.asset && (
                  <figure>
                    <div className={styles.imageWrap}>
                      <img
                        src={imageUrlFor(buildImageObj(node))
                          .auto("format")
                          .url()}
                        alt={node.alt}
                      />
                    </div>
                    {node.caption && <figcaption>{node.caption}</figcaption>}
                  </figure>
                )}
              </div>
            );
          })}
        </Slider>
      </Container>
    </div>
  );
}

export default Gallery;
